<template>
  <review-card :key="`list-group-chackra-${expanded}`" title="Chakras">
    <v-list-group
      v-for="(item, index) in chakras"
      :key="index"
      class="item-par"
      :value="expanded"
    >
      <template v-slot:activator>
        <v-list-item-title>
          {{ item.chakra.name }}
        </v-list-item-title>
      </template>
      <div class="pb-3 grey--text text--darken-3">
        <image-section :image="item.chakra.image" />
        <property-value
          property="Esconder nomes ao paciente"
          :value="hideNamesChakras | booleanToString"
        />
        <property-value
          property="Atuações"
          :value="item.procedures | arrayToString"
        />
        <property-value
          property="Comentários aos terapeutas"
          :value="item.therapistComments"
        />
        <property-value
          property="Comentários ao paciente"
          :value="item.patientComments"
        />
      </div>
    </v-list-group>
  </review-card>
</template>

<script>
export default {
  name: "Chakras",
  props: {
    hideNamesChakras: {
      type: Boolean,
      default: false,
    },
    chakras: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
